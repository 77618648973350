body {
  margin: 0;
  font-family:  'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
.ant-table-content::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

/* Track */
.ant-table-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ant-table-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.ant-table-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* width */
.ant-table-body::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

/* Track */
.ant-table-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ant-table-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.ant-table-thead .ant-table-cell {
  text-align: center;
}

.cs_table_title .ant-table-title {
  font-weight: 500;
  text-align: center;
  font-size: 17px;
}

.dropdown-noti .ant-dropdown-menu-root {
  box-shadow: none;
}

p {
  margin-bottom: 0;
}